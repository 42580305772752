<template>
    <div>
        <delete-items-button v-if="$hasPermission($permissions.RemoveSurveyResults)" class="mt-2" :selection="selection" @deleted="resetTable" @reset=" selection.selected_item_ids = []"/>
        <basic-table ref="basicTable" :columns="columns" :data="formattedResults" v-slot="props" @update-selection="updateSelection" @filter-update="filterUpdate">
            <div v-if="props.column.displayType === 3 && $hasPermission($permissions.RemoveSurveyResults)" class="d-flex justify-content-center">
                <b-form-checkbox  v-model="selection.selected_item_ids" :value="props.row.id"></b-form-checkbox>
            </div>
            <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                <b-button variant="success" @click="openSurveyResult(props.row)" class="mr-1">
                    <feather-icon icon="EyeIcon"/>
                </b-button>
                <b-button v-if="$hasPermission($permissions.RemoveSurveyResults) && showDelete" variant="danger" @click="$emit('on-delete-survey-result', props.row.id)" class="mr-1">
                    <feather-icon icon="Trash2Icon"/>
                </b-button>
            </div>
            <div v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                <span>{{moment(props.row.time).format('YYYY/MM/DD HH:mm')}}</span>
            </div>
            <span v-else class="d-flex justify-content-center">
                {{ props.formattedRow[props.column.field] }}
            </span>
        </basic-table>
 

        <b-modal title="Result" v-model="surveyResultModalActive" no-close-on-backdrop>
            <template v-if="resultsObject.results" #default>
                <div v-for="(result, index) of resultsObject.results" :key="index">
                    <hr v-if="index !== 0">
                    <BasicOverview :data="result" :definitions="overviewDefinitionsResults"/>
                </div>
            </template>
            <template #modal-footer>
                <b-button variant="danger" @click="surveyResultModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
    import {BButton, BModal, BFormCheckbox} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import BasicOverview from '@/views/components/BasicOverview'
    import DeleteItemsButton from '@/views/components/DeleteItemsButton.vue'

    export default {
        components: {
            DeleteItemsButton,
            BasicOverview,
            BasicTable,
            BButton,
            BModal,
            BFormCheckbox
        },
        props: {
            showUser:{
                type: Boolean,
                default: true
            },
            showDelete:{
                type: Boolean,
                default: true
            },
            surveyResults: {
                type: Array
            }
        },
        data() {
            return {
                dataLoaded: false,
                selection:{
                    selected_item_ids:[],
                    collection_name:''
                },
                columns: [
                    {
                        label: 'Select',
                        displayType: 3,
                        field: 'select',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Survey',
                        displayType: 0,
                        field: 'survey',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Outlet/Promotional item',
                        displayType: 0,
                        field: 'outlet_promotional_item',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Time',
                        displayType: 2,
                        field: 'time',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],
                resultsObject: {},
                surveyResultModalActive: false,
                overviewDefinitionsResults: [
                    { key: 'question', name: 'Question', type: 0 },
                    { key: 'answer', name: 'Answer', type: 0 }
                ],
                groupByOutlet: true

            }
        },
        methods: {
        
            removeSurveyResult(id) {
                const thisIns = this
                const removeResultPromise = this.$http.delete(`/api/management/v1/survey_result/${  id}`)
                removeResultPromise.catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            openSurveyResult(object) {
                this.resultsObject = object
                this.surveyResultModalActive = true
            },
            resetTable() {
                this.$refs.basicTable.$refs.table.reset()
                this.$emit('reload')
            },
            updateSelection(e) {
                if (this.$refs.basicTable.$refs.table.paginated[0] && this.$refs.basicTable.$refs.table.processedRows[0] && e) {
                    let arr = []
                    const pagResults = this.$refs.basicTable.$refs.table.paginated[0].children
                    const filterResults = this.$refs.basicTable.$refs.table.processedRows[0].children

                    arr = pagResults.filter(element => filterResults.includes(element))
                    arr.forEach((item) => {
                        const el =  this.selection.selected_item_ids.includes(item.id)
                        if (!el)   this.selection.selected_item_ids.push(item.id)
                    })
                } else {
                    this.selection.selected_item_ids = []
                }
            },
            filterUpdate(e) {
                e.forEach((item) => {
                    const el =  this.selection.selected_item_ids.includes(item.id)
                    if (el)   this.selection.selected_item_ids.splice(item.id)
                })
                this.$store.dispatch('app/updateSelectAll', false)
            }
        },
        computed: {
            formattedResults() {
                return this.surveyResults.map(result => {
                    return {
                        id: result.id,
                        survey: result.survey,
                        time: result.time,
                        outlet_promotional_item: (result.outlet.length > 0) ? result.outlet : result.promotional_item_serial_number,
                        user_id: result.user_id,
                        username: result.username,
                        user_name: result.user_name,
                        user_last_name: result.user_last_name,
                        results: result.results
                    }
                })
            }
        },
        watch: {
            dateFrom() {
                this.loadData()
            },
            dateTo() {
                this.loadData()
            }
        },
        mounted() {
            this.selection.collection_name = 'survey_results'

            if (this.showUser) {
                const userNameItem = {
                    label: 'User name',
                    displayType: 0,
                    field: 'user_name',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Search'
                    }
                }

                const userLastNameItem = {
                    label: 'User last name',
                    displayType: 0,
                    field: 'user_last_name',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Search'
                    }
                }

                this.columns.splice(2, 0, userNameItem)
                this.columns.splice(3, 0, userLastNameItem)
            }
        }
    }
</script>
<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Survey Results">
                <b-row>
                    <b-col>
                        <b-form-checkbox v-model="groupByOutlet">Group by Outlet</b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label>Date from</label>
                        <b-form-datepicker v-model="dateFrom" @input="loadData"/>
                    </b-col>
                    <b-col sm="4">
                        <label>Date to</label>
                        <b-form-datepicker v-model="dateTo" @input="loadData"/>
                    </b-col>
                    <b-col sm="4" v-if="$hasPermission($permissions.ExportExcel)">
                        <b-button class="mt-2" @click="onExportToExcel">Export to excel</b-button>
                        
                    </b-col>
                </b-row>
                <template v-if="groupByOutlet">
                    <survey-results-grouped-by-outlet @reload="loadData" @on-delete-survey-result="(surveyResultId) => removeSurveyResult(surveyResultId)" :showUser="showUser" :showDelete="showDelete" :surveyResults="surveyResults" />
                </template>
                <template v-else>
                    <survey-results-formatted @reload="loadData" @on-delete-survey-result="(surveyResultId) => removeSurveyResult(surveyResultId)" :showUser="showUser" :showDelete="showDelete" :surveyResults="surveyResults"/>
                </template>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>
    import {BButton, BCard, BOverlay, BFormCheckbox, BCol, BRow, BFormDatepicker} from 'bootstrap-vue'
    import SurveyResultsGroupedByOutlet from '@/views/components/SurveyResults/SurveyResultsGroupedByOutlet.vue'
    import SurveyResultsFormatted from '@/views/components/SurveyResults/SurveyResultsFormatted.vue'
    import $permissions from '@/permissions'

    export default {
        computed: {
            $permissions() {
                return $permissions
            }
        },
        components: {
            BFormDatepicker,
            BCard,
            BOverlay,
            BFormCheckbox,
            BCol,
            BRow,
            BButton,
            SurveyResultsGroupedByOutlet,
            SurveyResultsFormatted
        },
        props: {
            dataUrl: {
                type: String,
                required: true
            },
            dateFromProp: {
                type: Date,
                dafault: ''
            },
            dateToProp: {
                type: Date,
                dafault: ''
            },
            addDateToUrl:{
                type: Boolean,
                default: false
            },
            showUser:{
                type: Boolean,
                default: true
            },
            showDelete:{
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                dataLoaded: false,
                surveyResults: [],
                groupByOutlet: false,
                dateFrom:'',
                dateTo:''
            }
        },
        methods: {
            onExportToExcel() {
                const dateFrom = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                const dateTo = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')

                window.open(`/api/management/v1/export/survey/${this.$route.params.id}?date_from=${dateFrom}&date_to=${dateTo}`)
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                let url = this.dataUrl
        

                if (this.addDateToUrl) {
                    const parsedDateFrom = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                    const parsedDateTo = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')

                    url += `?date_from=${parsedDateFrom}&date_to=${parsedDateTo}`
                }

                const resultsPromise = this.$http.get(url)
                resultsPromise.then(function(response) {
                    thisIns.surveyResults = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            removeSurveyResult(id) {
                const thisIns = this
                const removeResultPromise = this.$http.delete(`/api/management/v1/survey_result/${  id}`)
                removeResultPromise.catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            setDates() {
                this.dateFrom = new Date()
                const newDate = new Date()
                newDate.setDate(newDate.getDate() + 7)
                this.dateTo = newDate
            }
        },

        watch: {
            dateFromProp(value) {
                this.dateFrom = value
                this.loadData()
            },
            dateToProp(value) {
                this.dateTo = value
                this.loadData()
            },
            dateFrom() {
                this.loadData()
            },
            dateTo() {
                this.loadData()
            }
        },
        mounted() {
            if (this.addDateToUrl) this.setDates()
            this.loadData()
        }
    }
</script>